.login {
  overflow: auto;
  background-color: rgb(243, 245, 246);
  margin: 0px;
  padding: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  
  .logo {
    width: 240px;
  }
  .inner {
    margin: auto;
    background: #ffffff;
    padding: 48px;
    transition: all 0.3s;
    border-radius: 8px;
    box-shadow: rgb(46 56 64 / 12%) 0px 4px 16px;
    width: 355px;
  }
  h4 {
    // color: #101213;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.25;
    margin-top: 15px;
  }
}
