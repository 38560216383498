

@mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: "%") {
  $attr-list: null;
  @for $i from 0 through $attr-count {
    $attr-value: $attr-steps * $i;

    .#{$attr}#{"-"}#{$attr-value} {
      #{$attr}: #{$attr-value}#{$unit} !important;
    }

    $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
  }
}

@include attr-x("min-width", 800, 1, "px");
@include attr-x("max-width", 800, 1, "px");
@include attr-x("width", 800, 1, "px");
@include attr-x("height", 800, 1, "px");
@include attr-x("right", 800, 1, "px");
@include attr-x("left", 800, 1, "px");
@include attr-x("top", 800, 1, "px");
@include attr-x("bottom", 800, 1, "px");

@include attr-x("min-height", 800, 1, "px");
@include attr-x("max-height", 800, 1, "px");
@include attr-x("font-size", 500, 1, "px");
@include attr-x("line-height", 500, 1, "px");
@include attr-x("border-radius", 100, 1, "px");

@include attr-x("margin", 800, 1, "px");
@include attr-x("margin-top-", -1, -800, "px");
@include attr-x("margin-top", 800, 1, "px");
@include attr-x("margin-bottom", 800, 1, "px");
@include attr-x("margin-left", 800, 1, "px");
@include attr-x("margin-right", 800, 1, "px");

@include attr-x("padding", 800, 1, "px");
@include attr-x("padding-top", 800, 1, "px");
@include attr-x("padding-bottom", 800, 1, "px");
@include attr-x("padding-left", 800, 1, "px");
@include attr-x("padding-right", 800, 1, "px");

@include attr-x("z-index", 800, 1, "");

