$spacer: 32px;

$btn-font-weight: 400;
$btn-font-size: 16px;
$btn-line-height: 24px;
$btn-padding-y: 8px;
$btn-padding-x: 12px;
$btn-border-width: 1px;

$tooltip-max-width: auto;

$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 12px;
$btn-font-size-sm: 14px;
$btn-border-radius-sm: 8px;

$btn-font-family: "Barlow", sans-serif;

$dropdown-link-active-bg: #52AFE3;
$font-family-sans-serif: "Barlow", sans-serif;
$font-family-base: "Barlow", sans-serif;
$font-size-base: 0.875rem;
$font-weight-bold: 600;

$nav-pills-link-active-bg: #495057;
$navbar-light-active-color: #ffffff;
$navbar-light-color: #495057;


$nav-link-font-weight: 600;
$headings-font-weight: 600;

$table-cell-padding-y-sm: 2px;
$table-cell-padding-x-sm: 4px;

$table-striped-bg: #F8F9FA;
$table-border-color: #E9ECEF;

$form-select-border-color: #CED4DA;
$form-select-border-radius: 8px;

$primary: #445f89;
$secondary: #09a3a4;
$tertiary: #52AFE3;
$tertiary-300: #cbe7f7;
$dark: #495057;
$light: #E9ECEF;
$headings-line-height: 28px;

$primary-colors: (
  1000: #445f89,
  900: #E61F2B,
  800: #E93842,
  700: #EB515A,
  600: #EE6A71,
  500: #F18389,
  400: #F49BA1,
  300: #F7B4B8,
  200: #F9CDD0,
  100: #FCE6E7
);

$green-colors: (
  1000: #46C569,
  900: #59CB78,
  800: #6BD187,
  700: #7ED696,
  600: #90DCA5,
  500: #A3E2B4,
  400: #B5E8C3,
  300: #C7EED2,
  200: #DAF3E1,
  100: #ECF9F0
);

$badge-padding-y: 4px;
$badge-padding-x: 6px;
$badge-font-size: 12px;
$badge-font-weight: 700;
$badge-line-height: 150%;


$form-check-input-checked-bg-color: $tertiary;
$form-check-input-checked-border-color: $tertiary;


$modal-inner-padding: 20px;
$modal-content-border-width: 0px;


body {
    font-family: "Barlow", sans-serif !important;
}