@import "../../assets/styles/config.scss";

.tabela-recuperacao-cliente {
    #tabela {
        // margin-top: 10px;

        .table-responsive {
            .table-header {
                position: sticky;
                top: 0;
                z-index: 25;
                background-color: #E9ECEF;
                border: none;

                th {
                    border: none;
                }
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--bs-primary);
            }
        }
    }

    .granularidade-nivel,
    .granularidade {
        position: sticky;
        left: 0px;
        z-index: 2;
    }

    .granularidade-nivel::after,
    .granularidade::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: var(--bs-dark);
    }


    .granularidade-nivel::before,
    .granularidade::before {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: #E9ECEF;

    }

    td {
        text-align: center;
    }

    th:nth-child(1) {
        background: #E9ECEF;
    }

    $column-widths: (
        20%,
        10%,
        10%,
        10%,
        10%,
        10%,
        10%,
        10%,
        10%
    ); // Lista de larguras das colunas

// Loop para atribuir larguras às colunas
@for $i from 1 through length($column-widths) {
    tr>td:nth-child(#{$i}) {
        width: nth($column-widths, $i);
    }
}

$column-widths-th: (
    20%,
    10%,
    10%,
    10%,
    30%,
    20%
);

@for $i from 1 through length($column-widths-th) {
    tr>th:nth-child(#{$i}) {
        width: nth($column-widths, $i);
    }
}


.mescado {
    th {
        padding: 0px;
    }
}

.faturamento {
    border-right: 1px solid gray;

    @for $i from 1 through 3 {
        tr>th:nth-child(#{$i}) {
            width: 33.3%;
        }

        tr>td:nth-child(#{$i}) {
            width: 33.3%;
        }
    }
}

.cluster {
    @for $i from 1 through 2 {
        tr>th:nth-child(#{$i}) {
            width: 50%;
        }

        tr>td:nth-child(#{$i}) {
            width: 50%;
        }
    }
}

td {
    font-size: 18px;
}
}