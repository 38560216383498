.tabela-centro-distribuicao {
    #tabela {
        margin-top: 10px;

        .table-responsive {
            .table-header {
                position: sticky;
                top: 0;
                z-index: 25;
                background-color: #E9ECEF;
                border: none;

                th {
                    border: none;
                }
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--bs-primary);
            }
        }
    }

    td:nth-child(1),
    .granularidade {
        position: sticky;
        left: 0px;
        z-index: 2;
    }

    td:nth-child(1)::after,
    .granularidade::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: var(--bs-dark);
    }


    td:nth-child(1)::before,
    .granularidade::before {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: #E9ECEF;

    }


    th:nth-child(1) {
        background: #E9ECEF;
    }

    @for $i from 1 through 500 {
        .width-#{$i} {
            width: #{$i}px !important;
            min-width: #{$i}px !important;
        }
    }
    .cursor-pointer{
        cursor: pointer;
    }
}