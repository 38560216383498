.table-row {
	text-align: center;
	.sort-absolute {
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
	}
	.fw-600 {
		font-weight: 600;
	}

}

