@import "../../assets/styles/config.scss";

.v-100 {
    .badge {
        $original-color: map-get($primary-colors, 400);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v-75 {
    .badge {
        $original-color: map-get($primary-colors, 300);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v-50 {
    .badge {
        $original-color: map-get($primary-colors, 200);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v-25 {
    .badge {
        $original-color: map-get($primary-colors, 100);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v0 {
    .badge {
        $original-color: #DEE2E6;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v25 {
    .badge {
        $original-color: map-get($green-colors, 200);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v50 {
    .badge {
        $original-color: map-get($green-colors, 300);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v75 {
    .badge {
        $original-color: map-get($green-colors, 400);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.v100 {
    .badge {
        $original-color: map-get($green-colors, 500);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.badge{
    min-width: 53px;
}

// .v-100 {
//     .badge {
//         $original-color: #F49BA1;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v-75 {
//     .badge {
//         $original-color: #E8A695;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v-50 {
//     .badge {
//         $original-color: #FFD4B0;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v-25 {
//     .badge {
//         $original-color: #FFEEA3;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v25 {
//     .badge {
//         $original-color: #D4DEBA;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v50 {
//     .badge {
//         $original-color: #D0E39D;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }

// }

// .v75 {
//     .badge {
//         $original-color: #BAE3BC;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v100 {
//     .badge {
//         $original-color: #8DEBA6;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v0 {
//     .badge {
//         $original-color: #b8b8b8;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v25 {
//     .badge {
//         $original-color: #F49BA1;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v50 {
//     .badge {
//         $original-color: #FFD4B0;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }

// }

// .v75 {
//     .badge {
//         $original-color: #D0E39D;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }

// .v100 {
//     .badge {
//         $original-color: #A3E2B4;
//         background-color: $original-color !important;
//         color: mix($original-color, black, 50%) !important;
//     }
// }