.congelado {
    .badge {
        $original-color: #DEE2E6;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

$color0: #A3E2B4;
$color1: #FFEDAB;
$color2: #F49BA1;


.color0 {
    color: $color0;
}

.color1 {
    color: $color1;
}

.color2 {
    color: $color2;
}

.resfriado-0 {
    .badge {
        $original-color: $color0;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.resfriado-30 {
    .badge {
        $original-color: $color1;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.resfriado-40 {
    .badge {
        $original-color: $color2;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }
}

.badge {
    min-width: 53px;
}