.SelectSearch {

  //width: 200px;
  .rbt-close-content {
    display: none;
  }

  .rbt {
    .rbt-input-main {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
    }
  }

  .scroll-select{
    .rbt-input-multi {
      max-height: 150px; /* Defina a altura máxima aqui */
      overflow-y: scroll; /* Adicione a rolagem quando necessário */
    }
  } 

  .has-aux {
    .rbt-input-main {
      background-image: none;
    }
  }

  .rbt-aux {
    position: absolute;
    right: 10px;
    top: 2px;
    width: 34px;
    z-index: 4;
  }

  .close {
    height: 25px;
    z-index: 3;
    background-color: white;
    border: 0;
    font-size: 10px;
  }

  mark {
    padding: 0 !important;
  }

  .rbt-token-removeable {
    .close {
      height: 1em;
      font-size: initial;
      background-color: transparent;
    }
  }

  .btn-search {
    width: 18px;
    height: 18px;
    line-height: 8px;
    font-size: 8px;
    padding: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    border: 0;
  }

  .margin-top--1 {
    margin-top: -2px;
    position: absolute;
  }

  .form-select {
    background-position: right 1.5rem center;
  }

  .typeahead-menu {
    z-index: 1050 !important;
    position: absolute !important;
  }
}