.layout {
  max-width: 100% !important;

  .layout-principal {
    padding-top: 100px;
  }

  .toast-header {
    background-color: initial;
    padding: 12px;
    justify-content: space-between;
    

    .btn-close {
      margin-right: 6px !important;
      margin-left: 6px !important;
      background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><g class="layer"><title>Layer 1</title><path d="m0.293,0.293a1,1 0 0 1 1.414,0l6.293,6.293l6.293,-6.293a1,1 0 1 1 1.414,1.414l-6.293,6.293l6.293,6.293a1,1 0 0 1 -1.414,1.414l-6.293,-6.293l-6.293,6.293a1,1 0 0 1 -1.414,-1.414l6.293,-6.293l-6.293,-6.293a1,1 0 0 1 0,-1.414z" fill="%23ffffff" id="svg_1"/></g></svg>');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}