
.pagina-404{
  .icon {
    width: 200px;
    height: 200px;
  }

  .erro-404 {
    margin: 0 auto;
    width: 420px;
  }
}