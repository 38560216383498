@import "../../assets/styles/config.scss";

.tabela-sazonalidade {
    #tabela {
        // margin-top: 10px;

        .table-responsive {
            .table-header {
                position: sticky;
                top: 0;
                z-index: 25;
                background-color: #E9ECEF;
                border: none;

                th {
                    border: none;
                }
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--bs-primary);
            }
        }
    }

    .granularidade-nivel,
    .granularidade {
        position: sticky;
        left: 0px;
        z-index: 2;
    }

    .granularidade-nivel::after,
    .granularidade::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: var(--bs-dark);
    }


    .granularidade-nivel::before,
    .granularidade::before {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: #E9ECEF;

    }

    th:nth-child(1) {
        background: #E9ECEF;
    }


    @for $i from 1 through 500 {
        .width-#{$i} {
            width: #{$i}px !important;
            min-width: #{$i}px !important;
        }
    }

    .badge {
        width: 100%;
        border-radius: 0 !important;
        margin: 0;
    }

    td {
        padding: auto;
    }



    .v-100 {
        $original-color: map-get($primary-colors, 400);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v-75 {
        $original-color: map-get($primary-colors, 300);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v-50 {
        $original-color: map-get($primary-colors, 200);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v-25 {
        $original-color: map-get($primary-colors, 100);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v0 {
        $original-color: #DEE2E6;
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v25 {
        $original-color: map-get($green-colors, 200);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v50 {
        $original-color: map-get($green-colors, 300);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v75 {
        $original-color: map-get($green-colors, 400);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

    .v100 {
        $original-color: map-get($green-colors, 500);
        background-color: $original-color !important;
        color: mix($original-color, black, 50%) !important;
    }

}