@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype"); 
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype"); 
    font-weight: bold;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-SemiBold.ttf") format("truetype"); 
    font-weight: 600;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Medium.ttf") format("truetype"); 
    font-weight: 500;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Light.ttf") format("truetype"); 
    font-weight: 300;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-ExtraLight.ttf") format("truetype"); 
    font-weight: 200;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-ExtraBold.ttf") format("truetype"); 
    font-weight: 800;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Black.ttf") format("truetype"); 
    font-weight: 900;
  }
  @font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow/Barlow-Thin.ttf") format("truetype"); 
    font-weight: 100;
  }
  