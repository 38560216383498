.centro-distribuicao {
  thead,
  tfoot {
    background: #e9ecef;
    color: #495057;
  }

  tbody {
    color: #495057;
    padding: 10px;
  }

  td {
    vertical-align: middle;
    text-align: end;
  }
 
  .drilldown {
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;

    .btn-sm {
      padding: 2px 4px !important;
    }

    .button:hover {
      color: var(--primary-color);
    }
  }

  .label-volume {
    position: absolute;
    font-size: 12px;
    color: var(--bs-danger) !important;
    font-weight: 700;
    margin-top: -17px;
  }

  .botao-cds {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .text-center{
    text-align: center !important;
  }
 
}