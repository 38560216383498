.switch-item {
  .uncheckedIcon{
    color: black;
    top: -3px;
    right: -15px;
    position: absolute;
  }

  .checkedIcon{
    color: black;
    top: -3px;
    left: -15px;
    position: absolute;
  }
}
